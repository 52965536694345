import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  readonly children: ReactNode | ReactNode[]
}

export const Portal = ({ children }: Props) => {
  const [mounted, setMounted] = useState(false)
  const rootDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    rootDiv.current = document.querySelector('#modal')

    if (!rootDiv.current) {
      rootDiv.current = document.createElement('div')
      rootDiv.current.id = 'root'
      document.body.appendChild(rootDiv.current)
    }

    setMounted(true)
  }, [])

  if (!mounted) return <></>

  return createPortal(children, rootDiv.current!)
}
