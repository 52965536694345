import * as Sentry from '@sentry/nextjs'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAppContext } from '~/components/context'
import Layout from '~/components/layout'
import { signInCallbackUrl } from '~/components/login/login-button'
import SEO from '~/components/seo'
import { DarkPageSTyle } from '~/styles/global'
import Api from '~/utils/api'

import Benefits from './benefits'
import FAQ from './faq'
import Header from './header'
import OptOutConfirmation from './opt-out-confirmation'
import EarningPoints from './points'
import Tiers from './tiers'

const Home: NextPage = () => {
  const {
    login,
    logout,
    addNotification,
    isNativeApp,
    token,
    user,
  } = useAppContext()

  const { query, pathname, ...router } = useRouter()
  const hasOptedOut = query.optOut !== undefined

  const handleMtCallback = async (code: string) => {
    let loginData: { token: string; user: User } | undefined

    try {
      loginData = await Api().login(code, 'coolcode')
      login(loginData.token, loginData.user)

      addNotification({
        text: 'You have been successfully logged in.',
        isPositive: true,
      })

      router.push('/dashboard')
    } catch (err) {
      addNotification({
        text: `There has been an error: ${err.message}`,
        isNegative: true,
      })

      Sentry.captureException(new Error('Error on login'), {
        extra: {
          error: err?.message,
          code,
          user: user?.email ?? loginData?.user,
        },
      })
    }

    router.replace('/', '/', { shallow: true })
  }

  useEffect(() => {
    if (hasOptedOut) {
      logout()
    }
  }, [hasOptedOut])

  useEffect(() => {
    const code = query.code

    if (isNativeApp && !token && pathname !== '/mariana-tek/callback') {
      Sentry.captureMessage('isNativeApp && !token')
      document.location.replace(signInCallbackUrl)
    } else if (typeof code === 'string' && !!code && !user) {
      Sentry.captureMessage('handleMtCallback')
      handleMtCallback(code)
    }
  }, [])

  return (
    <Layout>
      <DarkPageSTyle />
      <SEO title="The Academy" />

      <Header />
      <EarningPoints />
      <Benefits />
      <Tiers />
      <FAQ />

      {hasOptedOut && <OptOutConfirmation />}
    </Layout>
  )
}

export default Home
