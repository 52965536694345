import styled from 'styled-components'

import mq from '~/styles/mq'

import { Section as SectionBase } from '../styled'

export const Section = styled(SectionBase)`
  margin-top: 12rem;

  ${mq.medium} {
    margin-top: 15rem;
  }
`

export const Tiles = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 3rem 1rem;

  ${mq.smallOnly} {
    grid-template-columns: 1fr 1fr;
  }

  ${mq.medium} {
    grid-gap: 3.2rem;
  }
`
