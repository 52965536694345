import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <path
      d="M12.664 13.168H3.5l7.417 5.661L8.082 28l7.418-5.664L22.917 28l-2.835-9.171 7.418-5.661h-9.168L15.5 4z"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </Svg>
)

export default Icon
