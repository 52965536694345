import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 316 440">
    <g fill="currentColor">
      <path d="M193.975,333.177l-37.287-32.285-27.839,24.453L.014,438.167,0,323.477l157-137.91,51.334,45.153,21.68,19.062,0-.005,22.811,20.065.665.588,62.494,54.958L316,440Zm-6.053-190.861-31.1-27.114-27.969,24.57L.011,252.586,0,137.9,157,0l54.55,47.977,18.456,16.228,85.967,75.608L316,254.433Z" />
      <path d="M193.975,333.177l-37.287-32.285-27.839,24.453L.014,438.167,0,323.477l157-137.91,51.334,45.153,21.68,19.062,0-.005,22.811,20.065.665.588,62.494,54.958L316,440Zm-6.053-190.861-31.1-27.114-27.969,24.57L.011,252.586,0,137.9,157,0l54.55,47.977,18.456,16.228,85.967,75.608L316,254.433Z" />
    </g>
  </Svg>
)

export default Icon
