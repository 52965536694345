import Color from 'color'
import styled, { css } from 'styled-components'

import Container from '~/components/container'
import ChevronIconBase from '~/components/icons/chevron2'
import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { heading, paragraph, label } from '~/styles/typography'

import { Heading } from '../styled'

const indent = css`
  ${mq.medium} {
    padding-left: 10rem;
  }
`

export const Section = styled.section`
  overflow: hidden;
`

export const Title = styled(Heading)`
  position: absolute;
  margin: 0;

  ${mq.smallOnly} {
    padding-top: 5rem;
  }
`

export const Name = styled.h3`
  ${heading.skewed}
  position: relative;
  font-size: 4rem;
  margin-top: -1em;
  padding-top: 0.5em;

  ${mq.medium} {
    font-size: 9rem;
  }
`

export const Description = styled.p`
  ${paragraph.normal}
  ${indent}
  position: relative;
  font-size: 1.6rem;
  margin: 3rem 0 2rem;
  max-width: 34em;
`

export const List = styled.ul`
  &::before {
    content: '';
    display: block;
    height: 34rem;
  }

  ${mq.medium} {
    &::before {
      height: 54rem;
    }
  }
`

export const Tier = styled.li<{ tierName: string; nextTierName?: string }>`
  padding: 1rem 0 14rem;

  ${Container} {
    position: relative;
  }

  &:last-child {
    padding-bottom: 8rem;
  }

  ${mq.medium} {
    padding-bottom: 45rem;
  }
`

export const Conditions = styled.span`
  ${label}
  ${indent}
  display: block;
  position: relative;

  ${mq.smallOnly} {
    font-size: 1.1rem;
    padding-left: 3rem;
  }

  ${mq.medium} {
    margin-left: -0.8rem;
    padding-top: 0.5em;
  }
`

export const Requirements = styled(Conditions)`
  ${label}
  display: block;
  margin-top: -2rem;

  ${mq.smallOnly} {
    font-size: 1.6rem;
  }

  ${mq.medium} {
    margin-top: -5rem;
    font-size: 2rem;
  }
`

export const Benefits = styled.ul`
  ${indent}
  ${label}
  position: relative;
  min-height: 18em;
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    padding: 1em 0;
  }

  svg {
    font-size: 3.2rem;
    margin: -0.5em 0.4em -0.5em 0;
    flex-shrink: 0;
  }
`

export const ImageContainer = styled.div`
  position: absolute;
  top: -19rem;
  left: -30%;

  ${mq.medium} {
    left: -6%;
    top: -42rem;
  }
`

export const Clip = styled.div<{
  clipId: string
  tierName: string
  previousTierName?: string
}>`
  width: 130vw;
  clip-path: ${props => `url(#${props.clipId})`};

  ${mq.medium} {
    width: 104rem;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 80.7%;
  }

  &::after {
    ${Overlay}
    content: "";
    background: linear-gradient(to bottom, #f0f0f400, #f0f0f4cc 75%);
  }

  img {
    ${Overlay}
    object-fit: cover;
  }
`

export const ChevronIcon = styled(ChevronIconBase)<{ tierName: string }>`
  position: absolute;
  left: 100%;
  top: 100%;
  margin: -12rem -8rem;
  font-size: 20rem;
  color: rgba(0, 0, 0, 0.02);

  ${mq.medium} {
    font-size: 44rem;
    margin: -26rem -21rem;
  }
`

export const Footnotes = styled.ol`
  margin: 0;
  padding-bottom: 3rem;
  counter-reset: footnotes-counter;
  font-size: 1.2rem;
  opacity: 0.5;
  border-bottom: 1px solid
    ${new Color(theme.color.text.light).fade(0.75).toString()};

  li {
    counter-increment: footnotes-counter;
  }

  li::before {
    content: counter(footnotes-counter) '.';
    padding-right: 0.4em;
  }
`
