import styled, { css } from 'styled-components'

import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import { heading } from '~/styles/typography'

export const Tile = styled.li`
  display: block;
`

export const ImageWrapper = styled.div`
  position: relative;
  color: white;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background: linear-gradient(to top, black, transparent);
  }
`

export const TileImage = styled.img`
  ${Overlay}
  color: transparent;
`

export const Description = styled.p`
  opacity: 0.7;
`

export const Title = styled.h3<{ small?: boolean }>`
  ${heading.skewed}
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0.4rem 0;
  font-weight: 900;
  font-size: 1.8rem;
  z-index: 1;

  ${props =>
    !props.small &&
    css`
      ${mq.medium} {
        font-size: 2.5rem;
      }
      ${mq.extraLarge} {
        font-size: 3rem;
      }
    `}
`
