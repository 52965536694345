import type { FC } from 'react'
import { useEffect } from 'react'

import { ItemContainer } from './styled'

import { useAppContext } from '../context'

const NotificationItem: FC<NotificationType> = ({ id, text, ...rest }) => {
  const { removeNotification } = useAppContext()

  useEffect(() => {
    const timeout = setTimeout(() => {
      removeNotification(id)
    }, 4000)

    return () => clearInterval(timeout)
  }, [])

  return <ItemContainer {...rest}>{text}</ItemContainer>
}

export default NotificationItem
