import styled from 'styled-components'

import Button from '~/components/button'
import FacebookBase from '~/components/icons/facebook'
import InstagramBase from '~/components/icons/instagram'
import TwitterBase from '~/components/icons/twitter'
import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import theme, { getColorByTierName } from '~/styles/theme'
import { heading } from '~/styles/typography'

const commonBreakpoint = mq.medium

export const Modal = styled.div<{ tierName: string }>`
  ${Overlay}
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);

  --tier-color: ${props => getColorByTierName(props.tierName)};
`

export const Dialog = styled.dialog`
  background-color: ${theme.color.background.light};
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem 3rem;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;

  ${commonBreakpoint} {
    max-width: 65rem;
    padding: 10rem 12.5rem 5rem;
  }
`

export const Congrats = styled.p`
  ${heading.all}
  margin: 0;
  font-size: 2rem;

  ${commonBreakpoint} {
    font-size: 3rem;
  }
`

export const TierName = styled.p`
  ${heading.skewed};
  color: var(--tier-color);
  font-size: 4.4rem;
  font-weight: 900;
  margin: 2rem 0 1.5rem;

  ${commonBreakpoint} {
    font-size: 6.2rem;
    margin-top: 3rem;
  }
`

export const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.4rem;

  ${commonBreakpoint} {
    margin-bottom: 3rem;
  }
`

export const BenefitsListContainer = styled.ul`
  width: 100%;
  max-width: 32.5rem;
`

export const TierBenefitItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid ${theme.color.border.light};

  &:last-of-type {
    border-bottom: none;
  }
`

export const TierBenefitIconContainer = styled.div`
  ${heading.all};
  width: 4.5rem;
  font-size: 1.3rem;
  color: var(--tier-color);

  > svg {
    font-size: 2.6rem;
    color: var(--tier-color);
  }
`

export const TierBenefitDescription = styled.p`
  ${heading.all};
  font-size: 1.3rem;
  margin: 1.4rem 0;
`

export const TierExtraBenefits = styled.p`
  ${heading.all};
  font-size: 1.3rem;
  color: var(--tier-color);
  margin: 0.6rem 0 0;
`

export const ButtonLetsGo = styled(Button)`
  flex: 0 0 auto;
  margin: 2rem 0 1.5rem;
  width: 100%;

  ${commonBreakpoint} {
    margin: 4rem 0 2rem;
  }
`

export const ShareSocialMedia = styled.p`
  font-size: 1.3rem;
  margin: 0 0 2rem;
`

export const Instagram = styled(InstagramBase)`
  font-size: 3rem;
  margin-right: 3.2rem;
`

export const Twitter = styled(TwitterBase)`
  font-size: 3rem;
  margin-right: 3.2rem;
`

export const Facebook = styled(FacebookBase)`
  font-size: 3rem;
`

export const CloseButton = styled.button`
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  border: none;
  cursor: pointer;
  appearance: none;
  background: none;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 2rem;
    border-top: 2px solid currentColor;
    top: 2rem;
    right: 1rem;
    margin: -1px 0;
    transform: rotateZ(45deg);
  }

  &::after {
    transform: rotateZ(-45deg);
  }
`
