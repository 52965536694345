import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <path
      fill="none"
      d="M27.5 27h-25v-1h2v-6.935a7.719 7.719 0 0 1 4.511-1.445 5.842 5.842 0 0 1 3.35 1.08l.261.15A6.466 6.466 0 0 0 16.259 20a5.954 5.954 0 0 0 3.53-1.182l.212-.135a5.084 5.084 0 0 1 3.155-1.064 7.41 7.41 0 0 1 3.728 1.081c.211.122.418.252.616.389V26h2v1h-2zm-14.063-9.551l-.259-.149A7.433 7.433 0 0 0 9.011 16 9.244 9.244 0 0 0 4.5 17.155V15.52A3.524 3.524 0 0 1 8.02 12h.48V7h1v5h6V7h1v5h6V7h1v5h.481a3.523 3.523 0 0 1 3.519 3.52v1.666A8.949 8.949 0 0 0 23.156 16a6.635 6.635 0 0 0-4.028 1.318l-.212.133a4.4 4.4 0 0 1-2.658.93 5.107 5.107 0 0 1-2.821-.932zM22.5 6V5h1v1zm-7 0V5h1v1zm-7 0V5h1v1z"
    />
    <path
      d="M26.5 26v-6.365a8.374 8.374 0 0 0-.118-.07c-1.083-.627-2.168-.945-3.226-.945-.89 0-1.393.131-2.62.909l-.213.135C18.953 20.53 17.898 21 16.26 21c-1.237 0-2.262-.204-4.136-1.282l-.262-.152c-1.4-.81-1.94-.946-2.85-.946-1.251 0-2.43.331-3.511.986V26h21m-10.24-8.62c.662 0 1.083-.117 2.124-.776l.209-.131c1.54-.977 2.669-1.473 4.563-1.473 1.121 0 2.24.21 3.344.624v-.104c0-1.39-1.13-2.52-2.52-2.52H8.02c-1.39 0-2.52 1.13-2.52 2.52v.087A10.272 10.272 0 0 1 9.01 15c1.85 0 3.024.483 4.669 1.434.128.075.128.075.257.148 1.192.687 1.661.798 2.323.798M27.5 27h-25v-1h2v-6.935c1.367-.96 2.884-1.445 4.51-1.445 1.163 0 1.884.232 3.351 1.08l.261.151c1.688.971 2.55 1.15 3.637 1.15 1.404 0 2.29-.399 3.53-1.182l.212-.135c1.318-.836 1.996-1.064 3.155-1.064 1.236 0 2.49.363 3.728 1.08.21.122.417.253.616.39V26h2v1h-2zm-11.24-8.62c-.925 0-1.585-.218-2.823-.931l-.26-.15c-1.523-.88-2.536-1.3-4.166-1.3A9.244 9.244 0 0 0 4.5 17.156V15.52C4.5 13.58 6.08 12 8.02 12h.48V7h1v5h6V7h1v5h6V7h1v5h.48a3.523 3.523 0 0 1 3.52 3.52v1.666C26.075 16.399 24.613 16 23.156 16c-1.658 0-2.62.425-4.028 1.317l-.211.134c-1.14.72-1.736.93-2.658.93zM23.5 6h-1V5h1v1zm-7 0h-1V5h1v1zm-7 0h-1V5h1v1z"
      fill="currentColor"
    />
  </Svg>
)

export default Icon
