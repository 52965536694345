import type { FC } from 'react'

import { Tile, ImageWrapper, TileImage, Description, Title } from './styled'

const EarningPointsTile: FC<{
  readonly title: string
  readonly slug: string
  readonly description: string | JSX.Element
  readonly smallText?: boolean
}> = ({ title, slug, description, smallText }) => (
  <Tile key={title}>
    <ImageWrapper>
      <TileImage
        src={`/images/points/${slug}.jpg`}
        srcSet={`/images/points/${slug}@2x.jpg 2x`}
        loading="lazy"
        alt={title}
      />
      <Title small={smallText}>{title}</Title>
    </ImageWrapper>
    <Description>{description}</Description>
  </Tile>
)

export default EarningPointsTile
