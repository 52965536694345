import type { FC } from 'react'

import allTiersData from '~/constants/tiersData'

import {
  Modal,
  Dialog,
  Congrats,
  TierName,
  Paragraph,
  TierBenefitItem,
  TierBenefitIconContainer,
  TierBenefitDescription,
  TierExtraBenefits,
  ButtonLetsGo,
  // ShareSocialMedia,
  BenefitsListContainer,
  // Instagram,
  // Twitter,
  // Facebook,
  CloseButton,
} from './styled'

import { useAppContext } from '../context'

const HitNextTier: FC<{ readonly onClose: () => void }> = ({ onClose }) => {
  const { currentTier } = useAppContext()
  const tierNameLowerCase = currentTier?.name.toLowerCase()

  const newTier =
    tierNameLowerCase && tierNameLowerCase in allTiersData
      ? allTiersData[tierNameLowerCase as keyof typeof allTiersData]
      : null

  if (!newTier) {
    return null
  }

  return (
    <Modal tierName={newTier.name}>
      <Dialog open>
        <CloseButton onClick={onClose} />
        <Congrats>Congratulations</Congrats>
        <TierName>{newTier.name}</TierName>
        <Paragraph>
          Hard work pays off! Check out your additional loyalty benefits
        </Paragraph>
        <BenefitsListContainer>
          <ul>
            {Array.isArray(newTier.benefits) &&
              newTier.benefits.map(({ icon, label }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TierBenefitItem key={index}>
                  <TierBenefitIconContainer>{icon}</TierBenefitIconContainer>
                  <TierBenefitDescription>{label}</TierBenefitDescription>
                </TierBenefitItem>
              ))}
          </ul>
          {newTier.extraText && (
            <TierExtraBenefits>{newTier.extraText}</TierExtraBenefits>
          )}
        </BenefitsListContainer>
        <ButtonLetsGo type="button" onClick={onClose}>
          Let&apos;s Go
        </ButtonLetsGo>
        {/* <ShareSocialMedia>
          Share your milestone to earn <b>5 bonus points</b>
        </ShareSocialMedia>
        <div>
          <Instagram />
          <Twitter />
          <Facebook />
        </div> */}
      </Dialog>
    </Modal>
  )
}

export default HitNextTier
