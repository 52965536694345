import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import type { FC } from 'react'

import { useAppContext } from '~/components/context'
import Checkbox from '~/components/forms/checkbox'
import LoginButton from '~/components/login/login-button'
import Api from '~/utils/api'
import { isWaitingTier } from '~/utils/isWaitingTier'
import { optedOutError } from '~/utils/optedOutError'

import {
  PrivacyPolicy,
  JoinNowButton,
  SignInButton,
  PilotRestrictionBanner,
} from './styled'

import WaitingScreen from '../waiting-screen'

let pollCount = 0
let isWaiting = false

const HeaderActionButton: FC<{
  readonly invertedButton?: boolean
  readonly disabledPolling?: boolean
}> = ({ invertedButton, disabledPolling }) => {
  const [showWaitingScreen, setShowWaitingScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { addNotification, updateUser, user, token } = useAppContext()
  const [isChecked, setIsChecked] = useState(false)
  const router = useRouter()

  const userTierPolling = async (suppressError?: boolean) => {
    try {
      isWaiting = true

      const updatedUser = await Api().fetchUser()

      updateUser(updatedUser)
      pollCount += 1

      if (updatedUser.tier.name && !isWaitingTier(updatedUser.tier.name)) {
        await router.push('/dashboard/welcome')
      } else {
        setShowWaitingScreen(true)
        await Api().delay(Math.min(10000, 5000 + pollCount * 1000))
        isWaiting = false

        userTierPolling()
      }
    } catch (err) {
      isWaiting = false

      if (!suppressError) {
        addNotification({
          text: `There has been an error: ${(err as Error).message}`,
          isNegative: true,
        })
      }
    }
  }

  const handleJoin = async () => {
    setIsLoading(true)

    try {
      await Api().joinLoyalty()
      userTierPolling()
    } catch (err) {
      addNotification({
        text: `There has been an error: ${(err as Error).message}`,
        isNegative: true,
      })
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (
      !disabledPolling &&
      user &&
      (!user.tier.name || isWaitingTier(user.tier.name)) &&
      user.loyaltyOptIn &&
      !isWaiting
    ) {
      userTierPolling(true)
    }
  }, [showWaitingScreen, user?.tier.name])

  if (!!token && !!user && !user.hasPilotAccess) {
    return (
      <PilotRestrictionBanner>
        The Academy, a new loyalty program by Barry&rsquo;s, launches soon.
        Follow @barrys on Instagram for updates.
      </PilotRestrictionBanner>
    )
  }

  if (optedOutError(user)) {
    return (
      <p>
        <strong>
          Seems like you opted out of the loyalty program - reach out to{' '}
          <a href="mailto:theacademy@barrys.com">theacademy@barrys.com</a> with
          any questions and we&rsquo;ll be happy to assist you.
        </strong>
      </p>
    )
  }

  if (
    !!token &&
    (isWaitingTier(user?.tier.name) || !user?.tier.name) &&
    user?.loyaltyOptIn
  ) {
    return (
      <>
        <PrivacyPolicy>
          <Checkbox disabled checked /> I have read and agree to the Privacy
          Policy
        </PrivacyPolicy>

        <JoinNowButton disabled inverted={invertedButton} wide>
          Joining…
        </JoinNowButton>

        {showWaitingScreen && <WaitingScreen />}
      </>
    )
  }

  if (
    !!token &&
    !!user &&
    user.tier.name &&
    !isWaitingTier(user.tier.name) &&
    user.loyaltyOptIn
  ) {
    return (
      <Link href="/dashboard" passHref>
        <SignInButton inverted={invertedButton} as="span">
          Go to Dashboard
        </SignInButton>
      </Link>
    )
  }

  if (token) {
    return (
      <>
        <PrivacyPolicy>
          <Checkbox
            disabled={isLoading}
            checked={isChecked}
            onChange={evt => setIsChecked(evt.target.checked)}
          />{' '}
          I have read and agree to the Privacy Policy
        </PrivacyPolicy>

        <JoinNowButton
          isLoading={isLoading}
          inverted={invertedButton}
          wide
          disabled={!isChecked}
          onClick={() => {
            void handleJoin()
          }}
        >
          Join The Academy
        </JoinNowButton>
      </>
    )
  }

  return <LoginButton inverted={invertedButton} />
}

export default HeaderActionButton
