import styled, { css } from 'styled-components'

import { IconClose as IconCloseBase } from '~/components/icons/close'
import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { heading } from '~/styles/typography'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  transition: opacity 0.3s;
  background-color: rgba(100, 100, 100, 0.2);

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
  }
`

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: block;
  margin: 0.5rem 0.5rem 0 auto;
  padding: 1rem;
  color: inherit;

  ${mq.medium} {
    margin: 1.5rem 1.5rem 1rem auto;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Container = styled(Overlay)<{ animation?: 'slide' | 'grow' }>`
  background: none;
  transition: all 0.6s ${theme.ease.out};

  &.enter {
    transform: translateY(100%);
  }

  &.enter-active {
    transform: none;
  }

  &.exit {
    transform: none;
  }

  &.exit-active {
    transition-timing-function: ${theme.ease.in};
    transform: translateY(100%);
  }

  ${props =>
    props.animation === 'grow'
      ? css`
          &.enter {
            transform: scale(0.92);
            opacity: 0;
          }

          &.enter-active {
            transform: none;
            opacity: 1;
          }

          &.exit {
            transform: none;
            opacity: 1;
          }

          &.exit-active {
            transition-timing-function: ${theme.ease.in};
            transition-duration: 0.3s;
            transform: scale(0.92);
            opacity: 0;
          }
        `
      : css`
          opacity: 1 !important;
        `}
`

export const ModalBody = styled.div<{ fullscreen?: boolean }>`
  background-color: white;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  margin: 0 2rem;
  max-height: 100%;
  width: 100%;
  max-width: 65rem;
  overflow-y: auto;
  z-index: 9500;

  ${props =>
    props.fullscreen &&
    css`
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: 100%;

      ${CloseButton} {
        position: absolute;
        top: 0;
        right: 0;
      }
    `}
`

export const CloseIcon = styled(IconCloseBase)`
  font-size: 3rem;
`

export const SmallModalBody = styled(ModalBody)`
  position: relative;
  max-width: 50rem;
  max-height: calc(100% - 6rem);
  padding: 4rem 8rem;
  color: black;

  ${CloseButton} {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export const Title = styled.h1<{ centered: boolean }>`
  ${heading.h3}
  margin-bottom: 2.2rem;
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  text-transform: none;
  margin-top: 0;
`

export const Content = styled.div<{ centered?: boolean; hasPadding?: boolean }>`
  margin: 4rem -8rem -4rem;
  padding: 1rem 8rem 4rem;
  background-color: #fafafa;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  ${props =>
    props.hasPadding &&
    css`
      padding-top: 4rem;
    `}
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4rem -8rem -4rem;
  padding: 1rem 8rem 4rem;
  background-color: #fafafa;
`
