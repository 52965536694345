import Color from 'color'
import styled, { keyframes } from 'styled-components'

import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { paragraph } from '~/styles/typography'

const fadeIn = keyframes`
  from {
    opacity: 0;
    backdrop-filter: none;
  }
  to {
    opacity: 1;
    backdrop-filter: blur(10px);
  }
`

export const Container = styled.div`
  ${Overlay}
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: 1s ${fadeIn} ease-in-out forwards;
  background-color: ${new Color(theme.color.background.dark)
    .fade(0.4)
    .toString()};
  z-index: 1000;

  p {
    ${paragraph.large}
    max-width: 34em;
    padding: 0 2em;
    font-weight: 500;
  }
`

const rotate = keyframes`
  from {
    transform: none;
    opacity: 1;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.4;
    opacity: 1;
  }

  75% {
    opacity: 0.4;
  }

  to {
    transform: rotateY(-360deg);
    opacity: 1;
  }
`

export const LoadingContainer = styled.div`
  font-size: 5em;
  perspective: 1.6em;

  ${mq.medium} {
    font-size: 7em;
  }

  svg {
    animation: 5s ${rotate} linear infinite;
  }
`
