export const addFixedTierBenefits = (tierName = '') => {
  const tierNameLowercase = tierName?.toLowerCase()

  const rewards = {
    icon: [
      {
        id: Math.random().toString(),
        countSign: '∞',
        name: 'Priority waitlist',
      },
    ],
    legend: [
      {
        id: Math.random().toString(),
        countSign: '∞',
        name: 'Priority waitlist',
      },
      {
        id: Math.random().toString(),
        countSign: '∞',
        name: 'Complimentary water',
      },
      {
        id: Math.random().toString(),
        countSign: false,
        name: 'In-studio recognition',
      },
    ],
    vip: [
      {
        id: Math.random().toString(),
        countSign: '∞',
        name: 'Priority waitlist',
      },
      {
        id: Math.random().toString(),
        countSign: '∞',
        name: 'Complimentary water',
      },
    ],
  }

  return tierNameLowercase && tierNameLowercase in rewards
    ? rewards[tierNameLowercase as keyof typeof rewards]
    : []
}
