import type { ReactNode } from 'react'

import GlobalStyle from '~/styles/global'

import Footer from './footer'

import { useAppContext } from '../context'

interface Props {
  readonly children: ReactNode | ReactNode[]
  readonly fullscreenContent?: boolean
}

const Layout = ({ children, fullscreenContent }: Props) => {
  const context = useAppContext()

  return (
    <>
      <GlobalStyle />
      {children}
      {!context?.isNativeApp && (
        <Footer fullscreenContent={fullscreenContent} />
      )}
    </>
  )
}

export default Layout
