import NotificationItem from './item'
import { Wrapper } from './styled'

import { useAppContext } from '../context'

const Notifications = () => {
  const { notifications, isNativeApp } = useAppContext()

  return (
    <Wrapper isNativeApp={isNativeApp}>
      <div>
        {notifications.map(n => (
          <NotificationItem key={n.id} {...n} />
        ))}
      </div>
    </Wrapper>
  )
}

export default Notifications
