import type { FC } from 'react'

import { Button } from './styled'

const ScrollDownButton: FC<{
  readonly onClick: () => void
}> = ({ onClick }) => (
  <Button onClick={onClick} aria-label="Scroll down">
    <span />
  </Button>
)

export default ScrollDownButton
