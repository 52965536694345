import { useAppContext } from '~/components/context'
import Chevron from '~/components/icons/chevron'
import { optedOutError } from '~/utils/optedOutError'

import { Container, LoadingContainer } from './styled'

const WaitingScreen = () => {
  const { user } = useAppContext()

  if (optedOutError(user)) {
    return null
  }

  return (
    <Container>
      <p>
        We are preparing a customized loyalty experience just for you. This
        might take a few seconds.
      </p>
      <LoadingContainer>
        <Chevron />
      </LoadingContainer>
    </Container>
  )
}

export default WaitingScreen
