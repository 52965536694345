export enum ScreenSize {
  SM = 375,
  MD = 768,
  LG = 1025,
  XL = 1380,
}

export const mqWidthValues = {
  smallOnly: `(max-width: ${(ScreenSize.MD - 1) / 16}em)`,
  mediumOnly: `(min-width: ${ScreenSize.MD / 16}em) and (max-width: ${
    (ScreenSize.LG - 1) / 16
  }em)`,
  mediumLarge: `(min-width: ${ScreenSize.MD / 16}em) and (max-width: ${
    (ScreenSize.XL - 1) / 16
  }em)`,
  lessThanLarge: `(max-width: ${(ScreenSize.LG - 1) / 16}em)`,
  medium: `(min-width: ${ScreenSize.MD / 16}em)`,
  large: `(min-width: ${ScreenSize.LG / 16}em)`,
  extraLarge: `(min-width: ${ScreenSize.XL / 16}em)`,
}

const mq = {
  smallOnly: `@media ${mqWidthValues.smallOnly}`,
  mediumOnly: `@media ${mqWidthValues.mediumOnly}`,
  mediumLarge: `@media ${mqWidthValues.mediumLarge}`,
  lessThanLarge: `@media ${mqWidthValues.lessThanLarge}`,
  medium: `@media ${mqWidthValues.medium}`,
  large: `@media ${mqWidthValues.large}`,
  extraLarge: `@media ${mqWidthValues.extraLarge}`,
}

export default mq
