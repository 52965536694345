import type { ReactNode } from 'react'

import BirthdayIcon from '~/components/icons/birthday'
import BookingIcon from '~/components/icons/booking'
import Chevron from '~/components/icons/chevron2'
import ExerciseIcon from '~/components/icons/exercise'
import FuelIcon from '~/components/icons/fuel'
import StudioIcon from '~/components/icons/studio'
import TierIcon from '~/components/icons/tier'
import WaitlistIcon from '~/components/icons/waitlist'
import theme from '~/styles/theme'

export const tiersTable = [
  {
    name: 'CELEBRATE YOUR BIRTHDAY WITH A COMPLIMENTARY CLASS ON US',
    values: [true, true, true, true],
    secondaryIcon: <BirthdayIcon />,
  },
  {
    name: (
      <>
        RECEIVE 25% OFF IN THE BARRY’S SHOP ON YOUR BARRY’S BIRTHDAY - THE
        ANNIVERSARY OF THE DAY YOU JOINED THE FITFAM (U.S. ONLY). <sup>1</sup>
      </>
    ),
    values: [true, true, true, true],
    secondaryIcon: <BirthdayIcon />,
  },
  {
    name: 'Unlock access to exclusive promotions and Milestone Rewards.',
    values: [true, true, true, true],
    secondaryIcon: <TierIcon />,
  },
  {
    name: (
      <>
        Gain access to complimentary shipping for online apparel purchases (U.S.
        only). <sup>1</sup>
      </>
    ),
    values: [false, <>1&nbsp;occasion</>, <>3&nbsp;occasions</>, 'Unlimited'],
    mobileValues: [false, 'x1', 'x3', '∞'],
    secondaryIcon: <TierIcon />,
  },
  {
    name: 'Receive one complimentary guest pass each quarter.',
    values: [false, true, true, true],
    secondaryIcon: <TierIcon />,
  },
  {
    name: 'Unlock exclusive Academy pricing at the Fuel Bar.',
    values: [false, '5% off', '10% off', '10% off'],
    secondaryIcon: <FuelIcon />,
  },
  {
    name: (
      <>
        Unlock access to early booking. (U.S. only). <sup>1</sup>
      </>
    ),
    values: [false, false, '1 day earlier', '3 days earlier'],
    secondaryIcon: <BookingIcon />,
  },
  {
    name: 'Unlock 5x cancellation forgiveness each quarter.',
    values: [false, false, false, 'x5'],
    secondaryIcon: <ExerciseIcon />,
  },
  {
    name: 'Unlock 2x cancellation forgiveness each quarter.',
    values: [false, false, 'x2', false],
    secondaryIcon: <ExerciseIcon />,
  },
  {
    name: 'Unlock waitlist priority.',
    values: [false, false, false, true],
    secondaryIcon: <WaitlistIcon />,
  },
  {
    name: 'Unlock extended holds on your reserved spot in class.',
    values: [false, false, false, true],
    secondaryIcon: <StudioIcon />,
  },
] as Array<{
  name: ReactNode
  values: Array<boolean | string>
  mobileValues?: Array<boolean | string>
  secondaryIcon?: JSX.Element
}>

export interface TierData {
  name: string
  color: string
  points: string
  pointsDetails?: string
  description: string
  benefits?: Array<{
    icon: ReactNode
    secondaryIcon?: ReactNode
    label: ReactNode
  }>
  extraText?: string
  beneftisDetails?: string
  outline?: boolean
}

export const member: TierData = {
  name: 'Member',
  color: theme.color.tier.member,
  points: '0 - 999 stars',
  description:
    "Thanks for your loyalty to the Barry's Community. You deserve some Red Room Rewards. Here's what to expect: ",
}

export const red: TierData = {
  name: 'Red',
  color: theme.color.tier.red,
  points: 'Hit 1,000 stars',
  description:
    'As a Red member, you will receive these perks in addition to retaining all of your current Member Benefits:',
  extraText: '+ Member Benefits',
}

export const silver: TierData = {
  name: 'Silver',
  color: theme.color.tier.silver,
  points: 'Hit 2,500 stars',
  pointsDetails: 'Minimum 1,250 Fitness Stars & 50 Fashion + Fuel Stars',
  description:
    'As a Silver member, you will receive these perks in addition to retaining all of your current Red Benefits:',
  extraText: '+ Member & Red benefits',
}

export const black: TierData = {
  name: 'Black',
  color: theme.color.tier.black,
  points: 'Hit 6,000 stars',
  pointsDetails: 'Minimum 3,000 Fitness Stars & 200 Fashion + Fuel Stars',
  description:
    'As a Black member, you will receive these perks in addition to retaining all of your current Silver Benefits:',
  extraText: '+ Member, Red & Silver benefits',
}

// eslint-disable-next-line import/no-mutable-exports
let allTiersData = { member, red, silver, black }

allTiersData = Object.keys(allTiersData).reduce((acc, key, index) => {
  return {
    ...acc,
    [key]: {
      ...allTiersData[key as keyof typeof allTiersData],
      benefits: tiersTable
        .filter(
          row => !!row.values[index] && (index === 0 || !row.values[index - 1])
        )
        .map(row => ({
          icon: <Chevron />,
          secondaryIcon: row.secondaryIcon,
          label: row.name,
        })),
    },
  }
}, {}) as {
  member: TierData
  red: TierData
  silver: TierData
  black: TierData
}

export const allTiersArray = Object.keys(allTiersData).map(
  tierKey => allTiersData[tierKey as keyof typeof allTiersData]
)

export default allTiersData
