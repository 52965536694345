import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <g transform="translate(8.5 7)">
      <g fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path
          d="M4.89 0h13.22A1.89 1.89 0 0 1 20 1.89V19H3V1.89A1.89 1.89 0 0 1 4.89 0z"
          stroke="none"
        />
        <path d="M4.89.5h13.22a1.39 1.39 0 0 1 1.39 1.39V18a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V1.89A1.39 1.39 0 0 1 4.89.5z" />
      </g>
      <g
        transform="translate(8 11)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
      >
        <rect width="7" height="8" rx=".756" stroke="none" />
        <rect x=".5" y=".5" width="6" height="7" rx=".256" />
      </g>
      <g fill="currentColor">
        <rect width="23" height="1" rx=".5" transform="translate(0 18)" />
        <rect width="11" height="1" rx=".5" transform="translate(6 4)" />
        <rect width="11" height="1" rx=".5" transform="translate(6 8)" />
      </g>
    </g>
    <g fill="currentColor">
      <path d="M4.11 18.077l.564-.538.755.711L7.9 20.6v-2.522l-1.266-1.21-.013-.013-.462-.441-.44-.42L4.68 15 1.5 18.036v2.524z" />
      <path d="M4.11 14.077l.564-.538.755.711L7.9 16.6v-2.522l-1.266-1.21-.013-.013-.462-.441-.44-.42L4.68 11 1.5 14.036v2.524z" />
    </g>
  </Svg>
)

export default Icon
