type Ease = [number, number, number, number]

const easeValues = {
  in: [0.55, 0.06, 0.68, 0.19] as Ease,
  out: [0.22, 0.61, 0.36, 1] as Ease,
  inOut: [0.46, 0.03, 0.52, 0.96] as Ease,
}

const theme = {
  color: {
    background: {
      dark: '#F0F0F4',
      light: '#fff',
      lightGrey: '#F5F5F5',
    },
    text: {
      base: '#000',
      light: '#fff',
      grey: '#696969',
      lightGreen: '#8BC17E',
    },
    accent: {
      primary: '#D70036',
    },
    border: {
      light: '#E0E0E0',
    },
    tier: {
      red: '#D70036',
      silver: '#555659',
      black: '#000000',
      member: '#F5F3F3',
    },
  },
  zIndex: {
    modal: 5000,
    progressBar: 10000,
  },
  easeValues,
  ease: Object.keys(easeValues).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `cubic-bezier(${easeValues[key].join(', ')})`,
    }),
    { in: '', out: '', inOut: '' }
  ),
}

export const getColorByTierName = (tier?: string, defaultColor?: string) => {
  const tierLowerCase = tier?.toLowerCase()

  if (!tierLowerCase || !(tierLowerCase in theme.color.tier)) {
    return defaultColor ?? 'transparent'
  }

  return theme.color.tier[tierLowerCase as keyof typeof theme.color.tier]
}

export default theme
