import { useRouter } from 'next/router'
import { useState } from 'react'

import { Button } from '~/components/button'
import TickIcon from '~/components/icons/tick-ring'
import { SmallModal } from '~/components/modal/small'

import { ModalBody } from './styled'

const OptOutConfirmation = () => {
  const [isOpen, setIsOpen] = useState(true)
  const router = useRouter()

  const handleClose = () => {
    setIsOpen(false)
    router.replace('/', '/', { shallow: true })
  }

  return (
    <SmallModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Opt out successful"
      buttons={[
        <Button onClick={handleClose} key="close">
          Close
        </Button>,
      ]}
    >
      <ModalBody>
        <TickIcon />
        You have successfully opted out of the Loyalty program.
      </ModalBody>
    </SmallModal>
  )
}

export default OptOutConfirmation
