import type { FC, ReactNode } from 'react'

import { SmallModalBody, Title, Content, Buttons } from './styled'

import type { ModalProps } from '.'
import { Modal } from '.'

type Props = ModalProps & {
  readonly title: string
  readonly centerBody?: boolean
  readonly afterHeader?: ReactNode
  readonly buttons?: ReactNode
  readonly contentPadding?: boolean
  readonly centeredTitle?: boolean
  readonly children: ReactNode | ReactNode[]
}

export const SmallModal: FC<Props> = ({
  children,
  title,
  centerBody,
  afterHeader = null,
  buttons,
  contentPadding,
  centeredTitle = true,
  ...props
}) => (
  <Modal
    {...props}
    containerProps={{ animation: 'grow' }}
    ModalBodyComponent={SmallModalBody}
  >
    <Title centered={centeredTitle}>{title}</Title>
    {afterHeader}
    <Content hasPadding={contentPadding} centered={centerBody}>
      {children}
    </Content>
    {!!buttons && <Buttons>{buttons}</Buttons>}
  </Modal>
)
