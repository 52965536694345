import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <g transform="translate(.5 7)">
      <g fill="currentColor">
        <rect width="11" height="1" transform="translate(10 6)" />
        <rect width="11" height="1" transform="translate(10 11)" />
      </g>
      <g fill="none" stroke="currentColor" strokeMiterlimit="10">
        <g transform="translate(5)">
          <rect width="6" height="18" rx="3" stroke="none" />
          <rect x=".5" y=".5" width="5" height="17" rx="2.5" />
        </g>
        <g transform="translate(20)">
          <rect width="6" height="18" rx="3" stroke="none" />
          <rect x=".5" y=".5" width="5" height="17" rx="2.5" />
        </g>
        <g transform="translate(0 3)">
          <rect width="6" height="12" rx="3" stroke="none" />
          <rect x=".5" y=".5" width="5" height="11" rx="2.5" />
        </g>
        <g transform="translate(25 3)">
          <rect width="6" height="12" rx="3" stroke="none" />
          <rect x=".5" y=".5" width="5" height="11" rx="2.5" />
        </g>
      </g>
    </g>
  </Svg>
)

export default Icon
