import type { FC, MouseEventHandler, ReactNode } from 'react'
import { CSSTransition as ReactCSSTransition } from 'react-transition-group'

import { ModalBody, CloseButton, CloseIcon, Container, Overlay } from './styled'

import { Portal } from '../portal'

export interface ModalProps {
  readonly isOpen: boolean
  readonly fullscreen?: boolean
  readonly onClose?: MouseEventHandler<HTMLButtonElement>
  readonly ModalBodyComponent?: typeof ModalBody
  readonly containerProps?: {
    animation: 'slide' | 'grow'
  }
  readonly children: ReactNode | ReactNode[]
}

export const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  fullscreen,
  ModalBodyComponent = ModalBody,
  containerProps = {},
}) => {
  return (
    <Portal>
      <ReactCSSTransition timeout={600} in={isOpen} unmountOnExit>
        <Overlay />
      </ReactCSSTransition>

      <ReactCSSTransition timeout={600} in={isOpen} unmountOnExit>
        <Container {...containerProps} role="dialog">
          <ModalBodyComponent fullscreen={fullscreen}>
            {!!onClose && (
              <CloseButton onClick={onClose}>
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ModalBodyComponent>
        </Container>
      </ReactCSSTransition>
    </Portal>
  )
}
