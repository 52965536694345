import Color from 'color'
import styled, { keyframes } from 'styled-components'

import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { heading } from '~/styles/typography'

export const Section = styled.section`
  position: relative;
  margin: 7rem 0;
  padding-bottom: 5rem;
  border-bottom: 1px solid
    ${new Color(theme.color.text.light).fade(0.75).toString()};

  ${mq.medium} {
    padding-bottom: 9rem;
  }
`

const gradientHorizontalAnimation = keyframes`
  to {
    background-position: 0 100%;
  }
`

const gradientVerticalAnimation = keyframes`
  to {
    background-position: 50% 0;
  }
`

export const Title = styled.h1<{ inverted?: boolean }>`
  ${heading.skewed};
  margin-top: 30%;
  font-size: 12vw;
  color: ${Object.values(theme.color.tier)[0]};

  ${mq.medium} {
    font-size: 10rem;
  }

  ${mq.extraLarge} {
    font-size: 11rem;
    margin-top: 18rem;
  }

  span {
    color: transparent;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    animation: 1s ${gradientHorizontalAnimation} 0.5s
      cubic-bezier(0.22, 0.55, 0.34, 0.91) forwards;
  }

  span:first-child {
    display: block;
    background: linear-gradient(
        to right,
        ${props => (props.inverted ? 'white' : 'black')} 50%,
        transparent 50%,
        transparent 80%,
        ${props => (props.inverted ? 'white' : 'black')} 80%
      ) -10em 100%/7em 100% no-repeat;
  }

  span:nth-child(2) {
    animation: 0.6s ${gradientVerticalAnimation} 1.2s
      cubic-bezier(0.17, 0.84, 0.44, 1) forwards;
    background: linear-gradient(
        to bottom,
        ${props => (props.inverted ? 'white' : theme.color.accent.primary)} 50%,
        transparent 50%
      )
      50% -2em/100% 2em no-repeat;
  }

  span:nth-child(3) {
    animation-delay: 1.7s;
    background: linear-gradient(
        to right,
        ${props => (props.inverted ? 'white' : 'black')} 50%,
        transparent 50%,
        transparent 80%,
        ${props => (props.inverted ? 'white' : 'black')} 80%
      ) -6em 100%/6em 100% no-repeat;
  }
`

export const Heading = styled.h2`
  ${heading.h2}
  margin: 0 0 3rem;

  ${mq.medium} {
    margin: 0 0 7rem;
  }
`
