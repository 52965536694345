import styled from 'styled-components'

import { ButtonExternalLink } from '~/components/button'
import Container from '~/components/container'
import BarrysLogoBase from '~/components/icons/barrys-logo'
import mq from '~/styles/mq'
import { paragraph } from '~/styles/typography'

import HeroChevronsBase from './hero-chevrons'

export const Header = styled.header`
  position: relative;
  padding: 2rem 0;
  text-align: center;
  background: linear-gradient(
    to top,
    rgba(227, 227, 233, 0),
    rgb(227, 227, 233)
  );

  ${mq.smallOnly} {
    display: flex;
    flex-direction: column;
    height: 100%;

    ${Container} {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-start;
      margin-bottom: auto;
      padding-bottom: 9rem;
    }
  }

  ${mq.medium} {
    padding: 4rem 0 0;
  }
`

export const Chevrons = styled(HeroChevronsBase)`
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  transform: translateY(-40%);
  width: 90%;
  height: auto;
  overflow: visible;
  pointer-events: none;

  ${mq.extraLarge} {
    width: auto;
    height: 155%;
    top: auto;
    bottom: 0;
    transform: none;
  }
`

export const BarrysLogo = styled(BarrysLogoBase)`
  font-size: 8rem;

  ${mq.medium} {
    font-size: 15rem;
  }
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${mq.smallOnly} {
    margin-bottom: auto;
  }
`

export const Menu = styled.ul`
  li {
    display: inline-block;
    margin-left: 0.8rem;
  }

  ${mq.smallOnly} {
    li:not(:last-child) {
      display: none;
    }
  }
`

export const Intro = styled.p`
  ${paragraph.large}
  max-width: 34em;
  margin: 3rem auto;
`

export const ButtonLink = styled(ButtonExternalLink)`
  width: 14.6rem;

  ${mq.smallOnly} {
    padding: 0.7rem 1rem;
    width: auto;
  }
`
