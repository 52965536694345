import Head from 'next/head'

import Layout from '~/components/layout'
import LoginPrompt from '~/components/login'

interface Props {
  readonly title?: string
  readonly message?: string
}

const LoginPage = ({
  title = 'Login required',
  message = 'Please sign in to view this page.',
}: Props) => (
  <Layout fullscreenContent>
    <Head>
      <title>{title} | Barry&rsquo;s</title>
    </Head>
    <LoginPrompt title={title} message={message} />
  </Layout>
)

export default LoginPage
