import type { FC } from 'react'
import { memo } from 'react'

const ChevronClip: FC<{ readonly id: string }> = ({ id }) => (
  <svg width="0" height="0" viewBox="0 0 1 1">
    {/* eslint-disable-next-line react/forbid-dom-props */}
    <clipPath clipPathUnits="objectBoundingBox" id={id}>
      <path d="M0,.542V.993L.406.549l.088-.1.1.107L1,1V.55L.725.252.667.189.495,0Z" />
    </clipPath>
  </svg>
)

export default memo(ChevronClip)
