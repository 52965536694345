import Container from '~/components/container'
import EarningPointsTile from '~/components/earning-points-tile'
import data from '~/constants/earning-points-data'

import { Section, Tiles } from './styled'

import { Heading } from '../styled'

const EarningPoints = () => (
  <Container>
    <Section>
      <Heading>Earn Your Stars</Heading>
      <Tiles>
        {data.map(item => (
          <EarningPointsTile
            key={item.title}
            title={item.title}
            slug={item.slug}
            description={item.description}
          />
        ))}
      </Tiles>
    </Section>
  </Container>
)

export default EarningPoints
