import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 460 460">
    <g fill="none" stroke="currentColor" strokeWidth="19">
      <circle cx="216.5" cy="216.5" r="216.5" transform="translate(13 13)" />
      <path
        d="M579.626,194.147,412.312,361.461,337.6,286.748"
        transform="translate(-229.112 -48.304)"
      />
    </g>
  </Svg>
)

export default Icon
