import { addFixedTierBenefits } from './addFixedTierBenefits'
import { filterIsBenefit } from './filterIsBenefit'

export const offersToRewardsBenefits = (offers: Offer[], tierName?: string) => {
  const categorizedOffers = offers.reduce(
    (acc, offer) => {
      const isBenefit = filterIsBenefit(offer)

      return {
        rewards: isBenefit ? acc.rewards : [...acc.rewards, offer],
        benefits: !isBenefit ? acc.benefits : [...acc.benefits, offer],
      }
    },
    { rewards: [] as Offer[], benefits: [] as Offer[] }
  )

  return {
    ...categorizedOffers,
    benefits: [
      ...categorizedOffers.benefits,
      ...addFixedTierBenefits(tierName),
    ],
  }
}
