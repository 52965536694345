const data = [
  {
    title: 'Red Room Stars',
    slug: 'fitness',
    description: (
      <>
        Earn 100 stars for every in-studio class you take.* PLUS 100 bonus stars
        per month if you add Barry&rsquo;s X VOD to any Barry&rsquo;s
        membership.{' '}
        <em>
          (*Classes must be purchased and booked directly through Barry&rsquo;s)
        </em>
      </>
    ),
  },
  {
    title: 'Fuel Bar Stars',
    slug: 'fuel',
    description: (
      <>
        Earn four stars for every $1.00 spent at the Fuel Bar.
        <br />
        (Bonus: +100 stars on your first purchase of the quarter).
      </>
    ),
  },
  {
    title: 'Shop Stars',
    slug: 'fashion',
    description: (
      <>
        Earn four stars for every $1.00 spent on in-studio retail or at the
        Barry’s Shop.
        <br />
        (Bonus: +100 stars on your first purchase of the quarter).
      </>
    ),
  },
  {
    title: "Barry's X Stars",
    slug: 'challenges',
    description:
      "Earn 10 stars every $ spent on a Barry's X Membership PLUS earn 200 bonus stars for adding 5 friends to your Barry's X Fit Fam network each quarter.",
  },
  {
    title: 'Challenge stars',
    slug: 'friends',
    description:
      'Accelerate your earnings with exclusive challenges and special offers.',
  },
]

export default data
