import { css } from 'styled-components'

export const styleReset = css`
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  color: inherit;
  background: none;
`

export const Overlay = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
