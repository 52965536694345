import type { Context } from 'react'
import { createContext, useContext } from 'react'

export const AppContext = createContext<AppContextType | null>(null) as Context<
  AppContextType
>

export const useAppContext = () => {
  const context = useContext(AppContext)

  return context
}
