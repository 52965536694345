import styled, { css } from 'styled-components'

import { Button } from '~/components/button'
import ButtonWithLoading from '~/components/button/with-loading'
import { paragraph } from '~/styles/typography'

const commonButtonStyle = css`
  padding-left: 8rem;
  padding-right: 8rem;
  text-decoration: none;
`

export const PrivacyPolicy = styled.label`
  display: block;
  cursor: pointer;
  margin: 3rem 0;
`

export const JoinNowButton = styled(ButtonWithLoading).attrs({
  wide: true,
})`
  ${commonButtonStyle}
`

export const SignInButton = styled(Button).attrs({
  wide: true,
})`
  ${commonButtonStyle}
  margin-top: 2em;
`

export const PilotRestrictionBanner = styled.div`
  ${paragraph.large}
  max-width: 27em;
  margin: 1.5em auto 0;
  border-left: 3px solid red;
  padding-left: 0.7em;
  font-weight: 500;
`
