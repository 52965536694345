import { forwardRef } from 'react'

import { Wrapper, UI } from './styled'

type Props = {
  readonly className?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className = '', ...props }, ref) => (
    <Wrapper className={className}>
      <input type="checkbox" ref={ref} {...props} />
      <UI />
    </Wrapper>
  )
)

export default Checkbox
