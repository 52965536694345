import styled from 'styled-components'

import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import { label } from '~/styles/typography'

export const Container = styled.div`
  position: relative;
  color: white;

  ${mq.extraLarge} {
    position: sticky;
    top: 3rem;
  }

  img {
    display: block;
    width: 100%;
  }
`

export const BannerContent = styled.div`
  ${Overlay}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  background-color: rgba(0, 0, 0, 0.1);

  &::before,
  &::after {
    content: '';
    display: flex;
    flex: 5;
  }

  &::after {
    flex: 2;
  }
`

export const Label = styled.h3`
  ${label}
`
