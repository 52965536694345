export const formatMinimumPartialPoints = (tier?: Tier) => {
  if (!tier) {
    return ''
  }

  const { purchase, fitness } = tier.points

  const points = [
    fitness > 0 ? `${fitness.toLocaleString('en')} Fitness Stars` : '',
    purchase > 0 ? `${purchase.toLocaleString('en')} Fashion + Fuel Stars` : '',
  ]

  if (!purchase && !fitness) {
    return ''
  }

  return `Minimum ${points.filter(p => !!p).join(' & ')}`
}
