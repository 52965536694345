import styled from 'styled-components'

import theme from '~/styles/theme'

export const Wrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin: -0.2em 0.5em 0 0;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0.01;
  }
`

export const UI = styled.span`
  display: block;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #e9e9e9;
  transition: opacity 0.3;
  cursor: pointer;
  background-color: white;

  &:hover,
  input:focus &,
  label:hover & {
    border-color: black !important;
  }

  input:checked ~ &::after {
    content: '';
    display: block;
    margin: 8% 0 0 26%;
    width: 40%;
    height: 70%;
    transform: rotateZ(45deg);
    border-bottom: 3px solid ${theme.color.text.base};
    border-right: 3px solid ${theme.color.text.base};
  }

  input[disabled] ~ & {
    opacity: 0.3;
  }
`
