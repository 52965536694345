import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 403 87">
    <g transform="translate(0.662 0.067)" fill="currentColor">
      <path
        d="M0,117.663V37H44.99L55.9,48.02V70.174l-8.065,7.385L55.9,84.6v22.155L44.99,117.663Zm15.792-47.83H40.11V52.107H15.792Zm0,32.719H40.11V84.943H15.792Z"
        transform="translate(0 -33.592)"
      />
      <path
        d="M705.5,44.99V24.653L733.336,0l11.358,10.111L728.337,24.881Zm22.837,12.949-22.837,20V57.6L733.336,33.06,744.694,43.4Zm15.792,16.02-6.476,4.657,2.5,7.84-6.589-4.887-6.591,4.887,2.5-7.84-6.816-4.657h8.405l2.5-7.84,2.5,7.84Zm17.154-16.02.114,20.337L734.585,54.759l11.475-10.11ZM746.06,11.246l15.223,13.633.114,20.337L734.7,21.7Z"
        transform="translate(-640.514)"
      />
      <path
        d="M1455.767,86.856l10.907,32.039h-15.911l-10-30.789H1426.68v30.789H1411V38.23h44.306l11.02,10.907v27.04Zm-5.226-33.515h-23.857V73.109h23.857Z"
        transform="translate(-1281.029 -34.709)"
      />
      <path
        d="M2158.767,86.856l10.907,32.039h-15.909l-10-30.789H2129.68v30.789H2114V38.23h44.306l11.02,10.907v27.04Zm-5.225-33.515h-23.857V73.109h23.857Z"
        transform="translate(-1919.273 -34.709)"
      />
      <path
        d="M2792.467,85.853,2767.7,37h16.815l15.678,31.242L2816.1,37h16.928l-24.881,48.626v32.038h-15.679Z"
        transform="translate(-2512.759 -33.592)"
      />
      <path
        d="M3508.717,59.722h-7.157l3.294-10.563V37h13.179V47Zm16.815,45.672h-1.7l1.474-2.841h1.934Z"
        transform="translate(-3179.021 -33.592)"
      />
      <path
        d="M3766.65,117.663l-11.021-10.906V92.442h15.679v10.111h23.857V87.785l-30.447-6.816-9.089-9.77V48.02L3766.65,37h33.061l11.134,11.02V62.336h-15.679V52.224h-23.857V66.88l30.562,7.043,8.975,9.658v23.177l-11.134,10.906Z"
        transform="translate(-3409.688 -33.592)"
      />
    </g>
  </Svg>
)

export default Icon
