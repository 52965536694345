import type { FC, SVGAttributes } from 'react'
import styled, { css } from 'styled-components'

const SvgElement = styled.svg`
  display: inline-block;
  vertical-align: middle;
  width: ${props => props.width ?? '1em'};

  ${props =>
    !!props.height &&
    css`
      height: ${props.height};
    `}
`

const Svg: FC<SVGAttributes<SVGSVGElement>> = ({
  children,
  viewBox = '0 0 87 87',
  ...rest
}) => (
  <SvgElement xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...rest}>
    {children}
  </SvgElement>
)

export default Svg
