import styled, { keyframes } from 'styled-components'

import { styleReset } from '~/styles/misc'
import mq from '~/styles/mq'

const pulseDots = keyframes`
  to {
    transform: translateY(-0.5rem);
  }
`

const pulseArrow = keyframes`
  to {
    transform: translateY(-0.5rem) rotateZ(45deg);
  }
`

export const Button = styled.button`
  ${styleReset}
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 0 3rem;
  margin: 3rem 0;
  transform: translateX(-50%);
  cursor: pointer;

  ${mq.smallOnly} {
    margin-top: -8rem;
  }

  span {
    &::before,
    &::after {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin: 0.8rem auto;
      animation: 0.6s ${pulseDots} -0.15s infinite alternate;
      background-color: currentColor;
    }

    &::before {
      animation-delay: -0.3s;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 1.7rem;
    height: 1.7rem;
    margin-top: -1.2rem;
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
    transform: rotateZ(45deg);
    animation: 0.6s ${pulseArrow} infinite alternate;
  }
`
