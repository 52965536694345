export const tierPointsRequired = (
  tier: Tier | undefined,
  allTiers: Tier[],
  index: number
) => {
  if (index === 0) {
    return `0–${(allTiers[1]?.points.total - 1).toLocaleString('en')} stars`
  }

  return `Hit ${tier?.points.total.toLocaleString('en')} stars`
}
