import styled from 'styled-components'

import mq from '~/styles/mq'
import { label, paragraph } from '~/styles/typography'

import { Section as SectionBase } from '../styled'

export const Section = styled(SectionBase)`
  margin-bottom: 0;
  border-bottom: 0;

  ${mq.smallOnly} {
    margin-top: 5rem;
  }
`

export const Dl = styled.dl`
  display: grid;
  grid-template-columns: auto;
  align-items: stretch;
  grid-gap: 0 6rem;
  margin-top: 5rem;

  ${mq.extraLarge} {
    grid-template-columns: auto auto;
  }

  dt {
    ${label}
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  dd {
    ${paragraph.normal}
    font-size: 1.6rem;
    opacity: 0.6;
    margin: 0 0 auto;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid currentColor;
    opacity: 0.1;
    margin: 2.5rem 0;
  }

  &:last-child::after {
    display: none;
  }

  ${mq.extraLarge} {
    &:nth-last-child(2)::after {
      display: none;
    }
  }
`
