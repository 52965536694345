import Color from 'color'
import styled, { css } from 'styled-components'

import { allTiersArray } from '~/constants/tiersData'
import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { heading, label } from '~/styles/typography'

import { Section as SectionBase } from '../styled'

export const Section = styled(SectionBase)`
  margin: 0;
  padding: 0;
  border-bottom: none;
  background: linear-gradient(
    to bottom,
    ${new Color(theme.color.tier.member).fade(0.999).toString()} 30%,
    ${new Color(theme.color.tier.member).fade(0.5).toString()}
  );
`

export const Cols = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    flex-direction: row;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  padding-bottom: 5rem;

  ${mq.medium} {
    padding-bottom: 9rem;
    border-bottom: 1px solid
      ${new Color(theme.color.text.light).fade(0.75).toString()};
    flex: 1;
  }
`

export const BannerWrapper = styled.div`
  flex-shrink: 0;
  padding-bottom: 5rem;
  border-bottom: 1px solid
    ${new Color(theme.color.text.light).fade(0.75).toString()};

  ${mq.mediumLarge} {
    display: none;
  }

  ${mq.extraLarge} {
    width: 40rem;
    margin: -2rem 0 0 4rem;
    padding-bottom: 0;
    border-bottom: none;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    ${label}
    font-size: 1.3rem;

    &:not(:first-child) {
      color: #666;
    }
  }

  tbody th,
  td {
    padding: 1.4em 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  tbody th {
    text-align: left;
  }

  td,
  thead th {
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    ${mq.extraLarge} {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }

  svg {
    font-size: 2.2rem;
  }

  ${mq.smallOnly} {
    display: flex;
    flex-direction: column;

    thead,
    tbody,
    tr {
      display: contents;
    }

    tbody th {
      display: none;
    }

    td {
      display: flex;
      align-items: center;
    }

    thead th,
    td {
      text-align: left;
      padding: 1rem 0;

      &:empty {
        display: none;
      }

      ${allTiersArray.map(
        (_tier, index) => css`
          &:nth-child(${index + 2}) {
            order: ${index + 1};
          }
        `
      )}
    }
  }
`

export const TierName = styled.span`
  ${heading.skewed};
  display: block;
  font-size: 2.6rem;
  margin-bottom: 1rem;

  ${mq.smallOnly} {
    margin-top: 5rem;
    text-align: inherit;
  }
`

export const Label = styled.span`
  ${mq.medium} {
    display: none;
  }
`

export const Badge = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 3.5em;
  flex-shrink: 0;

  ${mq.smallOnly} {
    width: 5.5em;
    margin-right: 1em;
    flex-shrink: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mq.medium} {
    display: inline;
  }
`

export const MobileBadge = styled(Badge)`
  ${mq.smallOnly} {
    & + ${Badge} {
      display: none;
    }
  }

  ${mq.medium} {
    display: none;
  }
`
