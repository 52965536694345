export default {
  title: "Barry's",
  siteUrl: process.env.NEXT_PUBLIC_APP_URL,
  shopUrl: process.env.NEXT_PUBLIC_SHOP_URL,
  homeUrl: process.env.NEXT_PUBLIC_HOME_URL,
  chirpifyUrl: process.env.NEXT_PUBLIC_CHIRPIFY_URL,
  marianaTekUrl: process.env.NEXT_PUBLIC_MARIANATEK_URL,
  apiRoot: process.env.NEXT_PUBLIC_API_URL,
  mtClientId: process.env.NEXT_PUBLIC_MT_CLIENT_ID,
  googleTagManagerID: process.env.NEXT_PUBLIC_GTM_ID,
  devToken: process.env.NEXT_PUBLIC_DEV_TOKEN,
  hellWeekUrl: process.env.NEXT_PUBLIC_HELL_WEEK_URL,
}
