import type { ButtonHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import type { ButtonProps } from '.'
import { Button, LoadingIndicator } from '.'

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps & {
    readonly wide?: boolean
    readonly isLoading?: boolean
    readonly inverted?: boolean
    readonly children: ReactNode | ReactNode[]
  }

const ButtonWithLoading = forwardRef<HTMLButtonElement, Props>(
  ({ children, isLoading, disabled, ...props }, ref) => (
    <Button
      ref={ref}
      disabled={disabled ?? isLoading}
      isLoading={isLoading}
      {...props}
    >
      <span>{children}</span>

      {isLoading && (
        <LoadingIndicator viewBox="0 0 32 32">
          <circle
            cx="16"
            cy="16"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          />
        </LoadingIndicator>
      )}
    </Button>
  )
)

export default ButtonWithLoading
