import { createGlobalStyle } from 'styled-components'

import theme from '~/styles/theme'
import { font, heading } from '~/styles/typography'

export const DarkPageSTyle = createGlobalStyle`
  body {
    background-color: #F0F0F4 !important;
  }
`

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    font-size: 62.5%;
    font-family: ${font.base};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased; /* stylelint-disable-line property-no-unknown */
  }

  body {
    font-size: 1.4rem;
    color: ${theme.color.text.base};
    background-color: ${theme.color.background.light};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${heading.all}
    margin: 1em 0 0.4em;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  input {
    font: inherit;
    color: inherit;
  }

  #__next {
    height: 100%;
    display: flex;
    flex-direction: column;

    > * {
      flex-shrink: 0;
    }
  }

  #nprogress {
    z-index: ${theme.zIndex.progressBar};

    .bar { background: ${theme.color.accent.primary} !important; }
    .peg { box-shadow: 0 0 10px ${theme.color.accent.primary}, 0 0 5px ${theme.color.accent.primary} !important; }

    .spinner-icon {
      border-top-color:  ${theme.color.accent.primary} !important;
      border-left-color: ${theme.color.accent.primary} !important;
    }
  }

  body.locked {
    overflow: hidden;
  }
`

export default GlobalStyle
