import Color from 'color'
import styled, { keyframes, css } from 'styled-components'

export const Wrapper = styled.div<{ isNativeApp: boolean }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 3rem;
  pointer-events: none;
  text-align: center;
  z-index: 10000;

  > div {
    display: inline-block;
  }

  ${props =>
    props.isNativeApp &&
    css`
      margin-bottom: 7rem;

      > div {
        display: block;
      }
    `}
`

const animateIn = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  90% {
    transform: none;
    opacity: 1;
  }

  to {
    transform: translateX(-10%);
    opacity: 0;
  }
`

const baseBackgroundColor = new Color('#000')

export const ItemContainer = styled.div<{
  isPositive?: boolean
  isNegative?: boolean
}>`
  box-shadow: 0 3px 28px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 1em 1.4em;
  margin-top: 1rem;
  pointer-events: auto;
  animation: 3.5s ${animateIn} forwards;
  background-color: ${baseBackgroundColor.toString()};

  ${props =>
    (props.isPositive ?? props.isNegative) &&
    css`
      background-image: linear-gradient(
        to top right,
        ${baseBackgroundColor.toString()},
        ${baseBackgroundColor
          .mix(new Color(props.isPositive ? '#5EBA0E' : 'red'), 0.1)
          .toString()}
      );
    `}
`
