import styled from 'styled-components'

import Button from '~/components/button'
import ButtonWithLoading from '~/components/button/with-loading'
import Container from '~/components/container'
import { heading, paragraph } from '~/styles/typography'

export const Page = styled(Container)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const Title = styled.h1`
  ${heading.h1}
`

export const Paragraph = styled.p`
  ${paragraph.large}
`

export const SignInButton = styled(Button).attrs({
  wide: true,
})`
  padding-left: 8rem;
  padding-right: 8rem;
  text-decoration: none;
  margin-top: 2em;
`

export const SignInWithLoading = styled(SignInButton).attrs({
  as: ButtonWithLoading,
})``
