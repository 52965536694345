import styled from 'styled-components'

export const ModalBody = styled.p`
  text-align: center;
  margin-bottom: 0;

  svg {
    display: block;
    margin: 0.5em auto;
    font-size: 5em;
  }
`
