import { Logo } from '~/components/icons/logo'

import { Container, BannerContent, Label } from './styled'

import ActionButton from '../../action-button'

const Banner = () => (
  <Container>
    <img src="/images/join-banner.png" alt="Join The Academy" />

    <BannerContent>
      <Label>Join The Academy</Label>
      <Logo />
      <ActionButton disabledPolling />
    </BannerContent>
  </Container>
)

export default Banner
