import Link from 'next/link'
import type { ComponentProps, FC, ReactNode } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IStyledComponent } from 'styled-components'
import styled, { css, keyframes } from 'styled-components'

import Svg from '~/components/svg'
import { Overlay } from '~/styles/misc'
import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { label } from '~/styles/typography'

type LinkProps = ComponentProps<typeof Link>

export interface ButtonProps {
  readonly children: ReactNode | ReactNode[]
  readonly inverted?: boolean
  readonly textual?: boolean
  readonly outline?: boolean
  readonly wide?: boolean
  readonly wideAll?: boolean
  readonly small?: boolean
  readonly isLoading?: boolean
  readonly primary?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: inline-block;
  position: relative;
  border: none;
  margin: 0;
  padding: 1.8rem 2em;
  appearance: none;
  overflow: hidden;
  color: ${theme.color.text.light};
  text-align: center;
  transition: opacity 0.3s;
  text-decoration: none;
  background: ${theme.color.text.base};
  ${label}

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    opacity: 0.20;
    transition: 0.4s left, 0.4s width 0.2s;
    transform: skew(-45deg);
    transform-origin: 0 0;
    background: currentColor;
  }

  &:not([disabled]) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      &::before {
        left: calc(100% + 4.2em);
        width: 100%;
        transition: 0.4s width, 0.4s left 0.2s;
      }
    }
  }

  &[disabled] {
    opacity: 0.3;
  }

  ${props =>
    props.wide &&
    css`
      ${mq.smallOnly} {
        display: block;
        width: 100%;
      }
    `}

  ${props =>
    props.wideAll &&
    css`
      display: block;
      width: 100%;
    `}

  ${props =>
    (props.inverted ?? props.textual) &&
    css`
      color: ${theme.color.text.base};
      background: ${theme.color.text.light};
    `}

  ${props =>
    props.inverted &&
    css`
      &::before {
        opacity: 0.1;
      }
    `}

  ${props =>
    props.primary &&
    css`
      background-color: ${theme.color.accent.primary};
    `}

  ${props =>
    props.textual &&
    css`
      &::before {
        content: none;
      }

      &:hover,
      &:focus-visible {
        text-decoration: underline;
      }
    `}

  ${props =>
    props.outline &&
    css`
      border: 2px solid currentColor;
      background: transparent;
    `}

  ${props =>
    props.small &&
    css`
      padding: 0.5em 1em 0.45em;
      font-size: 1.1rem;
    `}

  ${props =>
    props.isLoading &&
    css`
      span {
        color: transparent;
      }
    `}
`

export const StyledTextLink = styled.span`
  opacity: 0.7;
`

export const ButtonExternalLink = (styled(Button).attrs({
  as: 'span',
})<ButtonProps>`
  text-decoration: none;
` as unknown) as IStyledComponent<any, ButtonProps>

export const ButtonLink: FC<
  Omit<LinkProps, 'passHref'> & ButtonProps & { readonly className?: string }
> = ({ children, className, inverted, outline, wide, ...linkProps }) => (
  <Link {...linkProps} passHref>
    <ButtonExternalLink {...{ className, inverted, outline, wide }}>
      {children}
    </ButtonExternalLink>
  </Link>
)

export const TextLink: FC<
  Omit<LinkProps, 'passHref'> & {
    readonly className?: string
    readonly children: ReactNode | ReactNode[]
  }
> = ({ children, className, ...linkProps }) => (
  <Link {...linkProps} passHref>
    <StyledTextLink className={className}>{children}</StyledTextLink>
  </Link>
)

const dash = keyframes`
  to {
    transform: rotateZ(450deg);
  }
`

export const LoadingIndicator = styled(Svg)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em;
  font-size: 2.8em;
  overflow: visible;

  circle {
    stroke-dasharray: 40;
    transform: rotateZ(90deg);
    transform-origin: 50% 50%;
    animation: ${dash} 1.5s linear infinite;
  }
`

const copiedFade = keyframes`
  from {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const copiedTextFade = keyframes`
  from {
    transform: translateY(0.5em);
    opacity: 0;
  }

  20% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: none;
    opacity: 1;
  }

  to {
    transform: translateY(-0.5em);
    opacity: 0;
  }
`

export const CopiedText = styled.span`
  ${Overlay}
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 2.5s ${copiedFade} forwards;
  background-color: #5eba0e;

  strong {
    animation: 2.5s ${copiedTextFade} forwards;
  }
`

export default Button
