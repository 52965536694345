import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <path
      d="M17.936 21.925l-2.007-1.685-1.5 1.276L7.5 27.4v-5.981l8.446-7.2 2.761 2.356 1.166.995 1.227 1.05.036.031 3.364 2.867V27.5zm-.326-10l-1.673-1.412-1.505 1.287L7.5 17.683V11.7l8.446-7.2L18.88 7l.994.847L24.5 11.8v5.982z"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </Svg>
)

export default Icon
