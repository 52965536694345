import Head from 'next/head'
import type { FC, ReactNode } from 'react'

import config from '~/utils/config'

interface Props {
  readonly description?: string
  readonly keywords?: readonly string[]
  readonly title?: string
  readonly type?: string
  readonly url?: string
  readonly image?: string
  readonly structuredData?: readonly StructuredData[]
  readonly children?: ReactNode | ReactNode[]
}

const SEO: FC<Props> = ({
  title,
  description = 'A complimentary membership program inviting our loyal community to receive rewards based on points earned.',
  keywords = [],
  structuredData = [],
  type = 'website',
  image = `${config.siteUrl}/images/og-share-v3.jpg`,
  url,
  children,
}) => {
  const websiteSD: StructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: title,
    alternateName: title,
  }

  return (
    <Head>
      <title>{title ? `${title} | ${config.title}` : config.title}</title>
      <script type="application/ld+json">
        {JSON.stringify([...structuredData, websiteSD])}
      </script>

      <meta name="description" content={description} />
      <meta name="keywords" content={(keywords || []).join(', ')} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url ?? config.siteUrl} />
      <meta property="og:image" content={image} />
      {children}
    </Head>
  )
}

export default SEO
