import styled, { css } from 'styled-components'

import mq, { ScreenSize } from '~/styles/mq'

const Container = styled.div<{ narrow?: boolean }>`
  max-width: ${ScreenSize.XL / 10}rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.6rem;

  ${mq.medium} {
    padding: 0 5rem;
  }

  ${props =>
    props.narrow &&
    css`
      max-width: 96rem;
    `}
`

export const DashboardContainer = styled(Container)`
  max-width: 102.8rem;
`

export default Container
