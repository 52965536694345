import type { FC } from 'react'

import Container from '~/components/container'
import ChevronIcon from '~/components/icons/chevron'

import { Footer, Links, LinksItem, Nav } from './styled'

const PageFooter: FC<{ readonly fullscreenContent?: boolean }> = ({
  fullscreenContent,
}) => (
  <Footer noMargin={fullscreenContent}>
    <Links>
      <Container>
        <dl>
          <LinksItem>
            <dt>Nominate Your Friends on Social</dt>
            <dd>Tag @barrys</dd>
          </LinksItem>
          <LinksItem>
            <dt>EARN YOUR STARS DIGITALLY</dt>
            <dd>
              <a
                href="https://itunes.apple.com/us/app/barrys-us/id1389503012?mt=8"
                rel="noopener noreferrer"
                target="_blank"
              >
                DOWNLOAD THE BARRY&rsquo;S X APP
              </a>
            </dd>
          </LinksItem>
        </dl>
      </Container>
    </Links>

    <Container>
      <Nav>
        <ChevronIcon /> © Barry’s {new Date().getFullYear()}
        <ul>
          <li>
            <a
              href="https://www.barrys.com/loyalty-program-terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Loyalty Program Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.barrys.com/terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.barrys.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </Nav>
    </Container>
  </Footer>
)

export default PageFooter
