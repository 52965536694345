const data = [
  {
    question: `What is the Academy?`,
    answer: `The Academy is the first of its kind; an exclusive Barry’s loyalty program that recognizes and rewards Barry’s clients across all facets of the Barry's experience.`,
  },
  {
    question: `What is the cadence of earning in the program?`,
    answer: (
      <>
        Unique to The Academy, timing of the program is based on annual quarters
        (UTC time):
        <br />
        January 1 – March 31
        <br />
        April 1 – June 30
        <br />
        July 1 – September 30
        <br />
        October 1 – December 31
      </>
    ),
  },
  {
    question: `What about my lifetime commitment to Barry’s?`,
    answer: `As part of the program, new lifetime milestones will be recognized. Stay tuned!`,
  },
  {
    question: `What are stars and how do I earn them?`,
    answer: `Stars are the points you earn as a member of The Academy for taking class in-studio, making friends on the Barry’s X Fit Fam network, and spending on Fuel, Retail, and Barry’s X. Stars are used to determine your status and benefits for the remainder of the quarter and the quarter following.`,
  },
  {
    question: `How and where do I redeem my Rewards?`,
    answer: `Please check your loyalty dashboard for your rewards. Many of the rewards associated with The Academy Program require no action taken on your end.`,
  },
  {
    question: `HOW ARE STARS CALCULATED?`,
    answer: (
      <>
        You earn +100 stars for every in-studio class taken*, +4 stars for every
        dollar spent on Fuel, and Retail, +10 stars for every dollar spent on
        Barry’s X and +200 stars for completing 5 social actions per quarter.{' '}
        <em>
          (*In-studio classes must be purchased and booked directly through
          Barry’s)
        </em>
      </>
    ),
  },
  {
    question: `When do I receive the benefits of the tiers I earn into?`,
    answer: `Once a client reaches a tier threshold, they will immediately be elevated into that status and retain the status through the end of the following quarter.`,
  },
  {
    question: `How did you determine my tier??`,
    answer: (
      <>
        If you earn 1,000 stars during the quarter, you will be in the Red Tier.
        <br />
        If you earn 2,500 stars during the quarter, you will be in the Silver
        Tier.
        <br />
        If you earn 6,000 stars during the quarter, you will be in the Black
        Tier.
      </>
    ),
  },
  {
    question: `Who do I reach out to I have questions about the loyalty program?`,
    answer: (
      <>
        You can submit any inquiries through our{' '}
        <a
          href="https://www.barrys.com/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us Form
        </a>{' '}
        for The Academy Loyalty Program or reach out directly to{' '}
        <a href="mailto:theacademy@barrys.com">theacademy@barrys.com</a>.
      </>
    ),
  },
  {
    question: `Unlock access to early booking`,
    answer: `Silver & Black Tier members can access 1- and 3-day early booking respectively using any membership or by purchasing an Early Booking Credit Package. Early Booking Credits are automatically discounted to match our standard 1, 5, and 10 pack rates. You will see this discount applied at checkout.`,
  },
  {
    question: `How do I redeem my late cancel forgiveness credits?`,
    answer: `Clients in our Silver tier receive 2 late cancel occasions per quarter. Clients in our Black tier receive 5 late cancel occasions per quarter. These will be automatically applied to late cancels: you will see a refund of the late cancel fee and be issued a “make good” credit that expires the same date as the credit/membership you originally used to book the class.`,
  },
  {
    question: `Are all studios across the globe a part of The Academy program?`,
    answer: `You can only earn and use stars in the US, Canada, and the UK (for now).`,
  },
  {
    question: `Will I get my Barry’s Birthday Reward automatically every year?`,
    answer: `Yes, if you attend one class within the last 12 months before your next Barry’s anniversary: the date of your first class.`,
  },
  {
    question: `WHERE CAN I EARN STARS? WHAT CURRENCY WILL BE USED TO CALCULATE THE STARS I EARN?`,
    answer: `As a member of The Academy, you can earn stars at any of our Barry's studios in the United States, Canada, and the UK and the Barry's Shop. Classes must be purchased and booked directly through Barry's. Spend will be converted to USD for all transactions before being used to calculate the stars earned.`,
  },
  {
    question: `WHAT ITEMS ARE NOT ELIGIBLE TO EARN STARS ON?`,
    answer: `You will earn 4 stars for every $ spent on Barry’s Shop, Food and Beverage, Water, Clothing, and Accessories purchases and 10 stars for every $ spent on Barry's X. Credit package / memberships, taxes, discounts, gift cards purchases and complimentary classes are not eligible for earning stars.`,
  },
]

export default data
