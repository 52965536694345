import Container from '~/components/container'
import Chevron from '~/components/icons/chevron2'
import { tiersTable, allTiersArray } from '~/constants/tiersData'

import Banner from './Banner'
import {
  Table,
  TierName,
  Section,
  Badge,
  MobileBadge,
  Label,
  Cols,
  TableWrapper,
  BannerWrapper,
} from './styled'

import { Heading } from '../styled'
import { Footnotes } from '../tiers/styled'

const Benefits = () => (
  <Section>
    <Container>
      <Heading>Program Benefits</Heading>

      <Cols>
        <TableWrapper>
          <Table>
            <thead aria-hidden="true">
              <tr>
                <th />
                {allTiersArray.map(tier => (
                  <th key={tier.name}>
                    <TierName>{tier.name}</TierName>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tiersTable.map((row, index) => (
                <tr key={index}>
                  <th>{row.name}</th>
                  {row.values.map((value, rowIndex) => (
                    <td key={rowIndex}>
                      {!!value && (
                        <>
                          {row.mobileValues && (
                            <MobileBadge>
                              {row.mobileValues[rowIndex]}
                            </MobileBadge>
                          )}
                          <Badge>
                            {typeof value === 'boolean'
                              ? value && <Chevron aria-hidden="true" />
                              : value}
                          </Badge>
                          <Label>{row.name}</Label>
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <BannerWrapper>
          <Banner />
        </BannerWrapper>
      </Cols>
    </Container>
    <Container>
      <Footnotes>
        <li>Excludes international shipping and equipment.</li>
      </Footnotes>
    </Container>
  </Section>
)

export default Benefits
