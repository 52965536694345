import { css } from 'styled-components'

import mq from './mq'

const font = {
  base: 'BentonSans, sans-serif',
  headings: '"Ford Antenna", sans-serif',
}

const allHeadingsStyle = css`
  font-family: ${font.headings};
  font-weight: bold;
  text-transform: uppercase;
`

const heading = {
  all: allHeadingsStyle,
  h1: css`
    ${allHeadingsStyle}
    font-size: 3.2rem;

    ${mq.medium} {
      font-size: 4.2rem;
    }
  `,
  h2: css`
    ${allHeadingsStyle}
    font-size: 2.1rem;

    ${mq.medium} {
      font-size: 3.7rem;
    }
  `,
  h3: css`
    ${allHeadingsStyle}
    font-size: 2.1rem;

    ${mq.medium} {
      font-size: 2.8rem;
    }
  `,
  skewed: css`
    font-family: ${font.base};
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -0.04em;
    font-style: italic;
    line-height: 0.9;
    transform-origin: 0 100%;
  `,
}

const label = css`
  ${allHeadingsStyle}
  font-size: 1.2rem;
`

const paragraph = {
  normal: css`
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  large: css`
    font-size: 1.4rem;
    line-height: 1.4;

    ${mq.medium} {
      font-size: 2rem;
    }
  `,
}

export { font, heading, paragraph, label }
