import type { FC } from 'react'

import Svg from '~/components/svg'

const HeroChevrons: FC<{ readonly className?: string }> = ({
  className = '',
}) => (
  <Svg className={className} viewBox="0 0 128 128">
    <path
      d="M705.5,66.608V36.5L746.711,0l16.816,14.97L739.31,36.836ZM739.31,85.78,705.5,115.385V85.279l41.211-36.333,16.816,15.308ZM762.69,109.5l-9.589,6.9L756.8,128l-9.755-7.235L737.29,128l3.7-11.607L730.9,109.5h12.444l3.7-11.607,3.7,11.607Zm25.4-23.718.169,30.109-39.7-34.816L765.55,66.1ZM765.55,16.65l22.537,20.183.169,30.109L748.728,32.127Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      vectorEffect="non-scaling-stroke"
      transform="translate(-655 10)"
      opacity="0.05"
    />
  </Svg>
)

export default HeroChevrons
