import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 32 32">
    <g transform="translate(5.5 2)">
      <g fill="none" stroke="currentColor" strokeMiterlimit="10">
        <rect width="21" height="27" rx="2" stroke="none" />
        <rect x=".5" y=".5" width="20" height="26" rx="1.5" />
      </g>
      <g fill="currentColor">
        <rect width="5" height="1" rx=".5" transform="translate(12 6)" />
        <rect
          data-name="Rectangle Copy 38"
          width="5"
          height="1"
          rx=".5"
          transform="translate(12 10)"
        />
        <rect
          data-name="Rectangle Copy 39"
          width="13"
          height="1"
          rx=".5"
          transform="translate(4 15)"
        />
        <rect
          data-name="Rectangle Copy 40"
          width="13"
          height="1"
          rx=".5"
          transform="translate(4 20)"
        />
        <path
          data-name="Fill 1 Copy 8"
          d="M6.166 7.167H3.875l1.854 1.415-.709 2.293 1.855-1.416 1.854 1.416-.709-2.293 1.855-1.415H7.583l-.708-2.292z"
        />
      </g>
    </g>
  </Svg>
)

export default Icon
