import styled, { css } from 'styled-components'

import mq from '~/styles/mq'
import theme from '~/styles/theme'
import { label } from '~/styles/typography'

export const Footer = styled.footer<{ noMargin?: boolean }>`
  color: ${theme.color.text.grey};
  font-size: 1.3rem;
  background: ${theme.color.background.light};

  ${props =>
    !props.noMargin &&
    css`
      margin-top: auto;
    `}
`

export const Links = styled.div`
  color: ${theme.color.text.base};
  font-size: 1.6rem;
  background: ${theme.color.background.lightGrey};

  dl {
    display: flex;
    margin: 0;
  }

  ${mq.smallOnly} {
    dl {
      flex-direction: column;
    }
  }
`

export const LinksItem = styled.div`
  padding: 3rem 0;

  ${mq.medium} {
    padding: 5rem 7rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:first-child) {
      border-left: 1px solid #e9e9e9;
    }
  }

  ${mq.smallOnly} {
    &:not(:first-child) {
      border-top: 1px solid #e9e9e9;
    }
  }

  dt {
    ${label}
  }

  dd {
    margin: 0.5em 0 0;
  }
`

export const Nav = styled.nav`
  display: flex;
  position: relative;
  padding: 4.5rem 0;

  ${mq.smallOnly} {
    flex-direction: column;
    padding-left: 4rem;
  }

  svg {
    color: ${theme.color.text.base};
    font-size: 2rem;
    margin-right: 0.8rem;

    ${mq.smallOnly} {
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 2.6rem;
      margin-top: -0.5em;
    }
  }

  ul {
    display: flex;

    ${mq.medium} {
      margin-left: auto;
    }
  }

  li {
    ${mq.smallOnly} {
      margin-right: 1.5em;
    }
    ${mq.medium} {
      margin-left: 2.5em;
    }
  }
`
