import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 31 25">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-941.000000, -303.000000)" fill="currentColor">
        <g transform="translate(941.000000, 303.000000)">
          <path d="M27.2880203,6.1095206 C27.3073263,6.37742622 27.3073263,6.64539075 27.3073263,6.91329637 C27.3073263,15.0847713 21.0353646,24.5002285 9.57204372,24.5002285 C6.04040826,24.5002285 2.75969166,23.4859218 0,21.7253907 C0.501778321,21.7827654 0.984191218,21.8019099 1.50527556,21.8019099 C4.41929658,21.8019099 7.10182356,20.8259512 9.24396018,19.1610249 C6.50357454,19.1035913 4.20704916,17.3238568 3.41579934,14.8742993 C3.80180094,14.931674 4.18774314,14.9699631 4.59305076,14.9699631 C5.15268774,14.9699631 5.71238412,14.8933849 6.23340906,14.759491 C3.3772467,14.1853318 1.23505068,11.6975442 1.23505068,8.6930309 L1.23505068,8.61651168 C2.06485313,9.07580371 3.02985714,9.36285386 4.05260099,9.40108402 C2.37363065,8.29111356 1.27366272,6.39657075 1.27366272,4.25320795 C1.27366272,3.10500733 1.58238083,2.05247046 2.1227712,1.1338864 C5.19124038,4.88474185 9.80359715,7.33424044 14.9755315,7.60220497 C14.8790608,7.14291294 14.8211427,6.66453528 14.8211427,6.18609872 C14.8211427,2.77966811 17.6001404,0.0048303124 21.0545518,0.0048303124 C22.8492989,0.0048303124 24.4703511,0.751172496 25.6089905,1.95680671 C27.017736,1.68890109 28.3686228,1.17217546 29.5651802,0.464122342 C29.1019545,1.89943202 28.1177633,3.10506624 26.8247946,3.87049404 C28.0792107,3.73660014 29.2950147,3.39205748 30.4142887,2.91367983 C29.565299,4.13839967 28.5038243,5.22916669 27.2880203,6.1095206 Z" />
        </g>
      </g>
    </g>
  </Svg>
)

export default Icon
