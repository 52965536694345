import Chevron from '~/components/icons/chevron'

import { Container, LoadingContainer } from './styled'

const LoadingScreen = () => (
  <Container>
    <LoadingContainer>
      <Chevron />
    </LoadingContainer>
  </Container>
)

export default LoadingScreen
