import Container from '~/components/container'

import data from './data'
import { Dl, Item, Section } from './styled'

import { Heading } from '../styled'

const FAQ = () => (
  <Section>
    <Container>
      <Heading>FAQ</Heading>

      <Dl>
        {data.map(item => (
          <Item key={item.question}>
            <dt>{item.question}</dt>
            <dd>{item.answer}</dd>
          </Item>
        ))}
      </Dl>
    </Container>
  </Section>
)

export default FAQ
