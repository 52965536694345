import SignInButton from './login-button'
import { Page, Title, Paragraph } from './styled'

interface Props {
  readonly title: string
  readonly message: string
}

const LoginPrompt = ({ title, message }: Props) => (
  <Page narrow>
    <Title>{title}</Title>
    <Paragraph>{message}</Paragraph>
    <SignInButton />
  </Page>
)

export default LoginPrompt
