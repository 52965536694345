import type { FC, SVGAttributes } from 'react'

import Svg from '~/components/svg'

const Icon: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 31 25">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1058.000000, -300.000000)" fill="currentColor">
        <g transform="translate(1058.000000, 300.000000)">
          <path d="M4.55622081,30.1599999 L4.55622081,16.6704687 L0,16.6704687 L0,11.3099999 L4.55622081,11.3099999 L4.55622081,7.08642184 C4.55622081,2.49762499 7.38381026,0 11.5123285,0 C13.490453,0 15.1893828,0.147265624 15.6824289,0.212062499 L15.6824289,5.00703123 L12.8191975,5.00703123 C10.5737589,5.00703123 10.1401159,6.06734372 10.1401159,7.61657809 L10.1401159,11.3099999 L15.2072038,11.3099999 L14.512187,16.6704687 L10.1401159,16.6704687 L10.1401159,30.1599999" />
        </g>
      </g>
    </g>
  </Svg>
)

export default Icon
