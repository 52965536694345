import { isWaitingTier } from './isWaitingTier'

export const optedOutError = (user: User | null) => {
  if (!user?.tier?.name) {
    return false
  }

  return (
    isWaitingTier(user.tier.name) &&
    user.tier.name.toString().toLowerCase().includes('opted') &&
    user.loyaltyOptIn
  )
}
