import Container from '~/components/container'
import { useAppContext } from '~/components/context'
import type { TierData } from '~/constants/tiersData'
import { allTiersArray } from '~/constants/tiersData'
import { ScreenSize } from '~/styles/mq'
import { formatMinimumPartialPoints } from '~/utils/formatMinimumPartialPoints'
import { tierPointsRequired } from '~/utils/tierPointsRequired'

import Chevron from './chevron'
import {
  Section,
  Title,
  List,
  Name,
  Description,
  Tier,
  Requirements,
  Conditions,
  Benefits,
  ImageContainer,
  Clip,
  ChevronIcon,
  Footnotes,
} from './styled'

const Tiers = () => {
  const { tiers } = useAppContext()

  return (
    <Section>
      <Container>
        <Title>The Levels</Title>
      </Container>

      <List>
        {allTiersArray.map((tier: TierData, index) => {
          const apiTier = tiers.data.find(t => t.name === tier.name)
          const nextTierName = allTiersArray[index + 1]?.name
          const previousTierName = allTiersArray[index - 1]?.name
          const partialPoints = formatMinimumPartialPoints(apiTier)
          const tierSlug = tier.name.toLowerCase().replace(' ', '')

          return (
            <Tier
              tierName={tier.name}
              nextTierName={nextTierName}
              key={tier.name}
            >
              <Container>
                <ImageContainer>
                  <Clip
                    tierName={tier.name}
                    previousTierName={previousTierName}
                    clipId="chevron-mask"
                  >
                    <img
                      src={`/images/tier-chevrons/${tierSlug}@520w.jpg`}
                      srcSet={`/images/tier-chevrons/${tierSlug}@520w.jpg 520w, /images/tier-chevrons/${tierSlug}@1040w.jpg 1040w, /images/tier-chevrons/${tierSlug}@2080w.jpg 2080w`}
                      sizes={`(max-width: ${
                        ScreenSize.MD - 1
                      }px) 130vw, 1040px`}
                      alt={tier.name}
                      loading="lazy"
                    />
                  </Clip>
                  <ChevronIcon tierName={tier.name} />
                </ImageContainer>

                <Name>{tier.name}</Name>
                {!!tier.points && (
                  <Requirements>
                    {tierPointsRequired(apiTier, tiers.data, index)}
                  </Requirements>
                )}
                {!!partialPoints && <Conditions>{partialPoints}</Conditions>}
                <Description>{tier.description}</Description>
                <Benefits>
                  {Array.isArray(tier.benefits) &&
                    tier.benefits.map((row, rowIndex) => (
                      <li key={rowIndex}>
                        {row.secondaryIcon ?? row.icon} {row.label}
                      </li>
                    ))}
                  {!!tier.extraText && <li>{tier.extraText}</li>}
                </Benefits>
              </Container>
            </Tier>
          )
        })}
      </List>

      <Chevron id="chevron-mask" />

      <Container>
        <Footnotes>
          <li>Excludes international shipping and equipment.</li>
        </Footnotes>
      </Container>
    </Section>
  )
}

export default Tiers
