export const buildQuery = (query?: object) => {
  if (!(query && typeof query === 'object' && Object.keys(query).length)) {
    return ''
  }

  return Object.keys(query)
    .map(
      key => `${key}=${encodeURIComponent(query[key as keyof typeof query])}`
    )
    .join('&')
}
